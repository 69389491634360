import axios from "@/utils/axios.js";
import apiPath from "./apiPath";
let { userApiPath } = apiPath;

//3.用户登录
export let signin = (userInfo) => axios.post(userApiPath.userSignin, userInfo);

//4.根据token获取用户菜单
export let getMenus = (userInfo) => axios.get(userApiPath.getUserMenus);

//5.token登录
export let validate = (token) => axios.get(userApiPath.userValidate);
