import Vue from 'vue'

//vue基础模块
import App from './App.vue'
import store from './store'
import router from './router'

//element-ui组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//css
import '@/assets/style/index.scss'

let vue=new Vue({
    render:h=>h(App),
    store,
    router,
})

vue.$mount('#app')

// 更新标记
console.log('version:9-22（9:03）')