<template>
    <header class="navBarPage">
        <el-breadcrumb class="nav-bar">
            <el-breadcrumb-item v-for="item in navArr" :key="item.path" :to="item">
                {{ item.name }}
            </el-breadcrumb-item>
        </el-breadcrumb>
        <div class="tab-view-container">
            <!-- v-for：把标签包装成函数，和tabArr一起放到_l()的参数中，会遍历tabArr然后运行函数 -->
            <div v-for="tab in tabArr" :key="tab.path" :class="tab.class">
                <router-link :to="tab.path">
                    {{ tab.name }}
                </router-link>
                <i @click="closeTab(tab.path)" class="fas fa-times close-tab-item"></i>
            </div>
            <button type="button" class="tab-item" @click="clearTabArr">清空</button>
        </div>
    </header>
</template>

<script>

export default {
    data() {
        return {
            navArr: [],
            tabArr: [{ name: '首页', path: '/' }],
        }
    },
    watch: {
        $route: 'update'
    },
    methods: {
        update() {
            this.getNavArr();
            this.getTabArr();
        },
        getNavArr() {
            this.navArr = [{ name: '首页', path: '/' }]
            if (this.$route.path == '/') {
                return
            }
            let routeArr = this.$route.matched;
            routeArr.forEach(item => {
                this.navArr.push({ name: item.name, path: item.path })
            })
        },
        getTabArr() {
            //先清空样式
            this.tabArr.forEach(item => {
                item.class = 'tab-item'
            })
            let route = this.$route.matched[1]
            //先看数组里面有没有
            let hasItem = this.tabArr.find(item => item.name == this.$route.name)
            if (!hasItem) {
                //数组里面没有的，要添加进去
                this.tabArr.push({ name: route.name, path: route.path, class: 'tab-item-active' })
            } else {
                //数组里面有的，要改变样式
                hasItem.class = 'tab-item-active'
            }
        },
        clearTabArr() {
            this.tabArr = [{ name: '首页', path: '/', class: 'tab-item-contain-active' }]
            if (this.$route.path != '/') {
                this.$router.push('/')
            }

        },
        closeTab(path) {
            this.tabArr = this.tabArr.filter(item => item.path != path)
            let itemPath = this.tabArr[this.tabArr.length - 1].path
            this.$router.push(itemPath)
            if (this.$route.path != itemPath) {
                this.$router.push(itemPath)
            }
        }
    },
    //对options.data进行了初始化，添加了get,set方法，但是并没有调用，也就没有进行依赖收集，这里修改data是不会触发视图更新的
    created() {
        this.update()

    },

}
</script>

<style scoped lang="scss">
@mixin tab-item-style {
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    padding: 5px;
    height: 27px;
    line-height: 15px;
    border: 1px solid #d8dce5;
    color: #495060;

    a {
        text-decoration-line: none;
        color: black;
    }
}

.nav-bar {
    height: 50px;
    background-color: white;
    padding-top: 16px;
    padding-left: 16px;
}

.tab-view-container {
    height: 33px;
    overflow: hidden;
    background-color: white;
    font-size: 12px;
    padding-top: 3px;
    padding-left: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);

    .tab-item {
        @include tab-item-style;
    }

    .tab-item-active {
        @include tab-item-style;
        background-color: #42b983;
        color: white;

        a {
            color: white;
        }

        ;
    }

    .tab-item-active::before {
        content: '';
        height: 8px;
        width: 8px;
        background-color: white;
        border-radius: 50%;
        display: inline-block;
    }

    .tab-item:last-child {
        float: right;
    }

    .close-tab-item {

        border-radius: 50%;

        &:hover {
            background-color: #b4bccc;
        }
    }
}</style>