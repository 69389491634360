var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      staticClass: "SideBarWrap",
      attrs: {
        router: "",
        "background-color": "#304156",
        "text-color": "#BFCBD9",
        "active-text-color": "#409EFF",
      },
    },
    [
      _vm._l(_vm.userMenuPermission, function (route) {
        return [
          route.name == ""
            ? [
                _c(
                  "el-menu-item",
                  { key: route.path, attrs: { index: route.path } },
                  [
                    _c("template", { slot: "title" }, [
                      _c("i", { class: [route.children[0].icon, "icon"] }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(route.children[0].name))]),
                    ]),
                  ],
                  2
                ),
              ]
            : [
                _c(
                  "el-submenu",
                  { key: route.path, attrs: { index: route.path } },
                  [
                    _c("template", { slot: "title" }, [
                      _c("i", { class: [route.icon, "icon"] }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(route.name))]),
                    ]),
                    _vm._v(" "),
                    _vm._l(route.children, function (item, index) {
                      return _c(
                        "el-menu-item",
                        {
                          key: index,
                          attrs: { index: route.path + "/" + item.path },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", { class: [item.icon, "icon"] }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.name))]),
                          ]),
                        ],
                        2
                      )
                    }),
                  ],
                  2
                ),
              ],
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }