import * as userApi from '../../api/userApi.js';
import allRoutes from '../../router/all.js';
import router from '../../router/index.js';
export default {
	state: {
		userInfo: {}, //用户信息
		isSignin: false,
		hasMenuPermission: false, //是否动态获取路由
		userMenuPermission: [], //用户路由信息
	},
	//这里接受两个参数，最后会包装成接受一个参数的函数
	actions: {
		//用户登录，获取用户信息
		async user_signin(context, payload) {
			let userInfo = await userApi.signin(payload);
			if (userInfo && userInfo.token) {
				localStorage.setItem('token', userInfo.token);
			} else {
				localStorage.clear('token');
			}
			context.commit('set_user', userInfo);
			context.commit('set_hasMenuPermission', true);
		},
        //token登录
		async user_validate(context, payload) {
			try {
				let userInfo = await userApi.validate();
				context.commit('set_user', userInfo);
				context.commit('set_isSignin', true);
			} catch (error) {
                console.log('error')
				context.commit('reset_signin');
				return Promise.reject(error);
			}
		},
		//动态添加路由
		async add_route(context, payload) {
			try {
				let menus = await userApi.getMenus(); //从服务器获取路由
                
				filterRoutes(menus, allRoutes); //给路由注册组件
				router.addRoutes(menus); //动态添加路由
				//更改store
				context.commit('set_hasMenuPermission', true);
				context.commit('set_userMenuPermission', menus);
			} catch (error) {
                // console.log(error)
            }
		},
	},
	mutations: {
		set_user(state, payload) {
			state.userInfo = payload;
		},
		set_isSignin(state, payload) {
			state.isSignin = payload;
		},
		set_hasMenuPermission(state, payload) {
			state.hasMenuPermission = payload;
		},
		set_userMenuPermission(state, payload) {
			state.userMenuPermission = payload;
		},
		reset_signin(state, payload) {
			localStorage.clear('token');
			state.userInfo = {}; //用户信息
			state.isSignin = false;
			state.hasMenuPermission = false; //是否动态获取路由
			state.userMenuPermission = []; //用户路由信息
		},
	},
};

//遍历userRoutes树，添加上组件
function filterRoutes(userRoutes, allRoutes) {
	function filter(userRoutes) {
		userRoutes.forEach(userRoute => {
			allRoutes.forEach(allRoute => {
				if (userRoute.permission_id == allRoute.id) {
					userRoute.component = allRoute.component;
				}
				if (userRoute.children && userRoute.children.length > 0) {
					filterRoutes(userRoute.children, allRoutes);
				}
			});
		});
	}
	filter(userRoutes);
}
