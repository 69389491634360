var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "navBarPage" },
    [
      _c(
        "el-breadcrumb",
        { staticClass: "nav-bar" },
        _vm._l(_vm.navArr, function (item) {
          return _c(
            "el-breadcrumb-item",
            { key: item.path, attrs: { to: item } },
            [_vm._v("\n            " + _vm._s(item.name) + "\n        ")]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab-view-container" },
        [
          _vm._l(_vm.tabArr, function (tab) {
            return _c(
              "div",
              { key: tab.path, class: tab.class },
              [
                _c("router-link", { attrs: { to: tab.path } }, [
                  _vm._v(
                    "\n                " + _vm._s(tab.name) + "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fas fa-times close-tab-item",
                  on: {
                    click: function ($event) {
                      return _vm.closeTab(tab.path)
                    },
                  },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "tab-item",
              attrs: { type: "button" },
              on: { click: _vm.clearTabArr },
            },
            [_vm._v("清空")]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }