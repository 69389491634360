let baseUrl = "/api";

let apiPath={}

//用户模块
apiPath.userApiPath = {
    //3.用户登录
    userSignin: "/admin/user/signin",
    //4.根据token获取用户菜单
    getUserMenus: "/admin/user/getMenus",
    //5.token登录
    userValidate: "/admin/user/validate",
};

//网课模块
apiPath.courseApiPath = {
    //1.获取任务列表
    getCourseList: "/course/getList",
    //2.添加一个任务
    addCourseTask: "/course/add",
    //3.删除一个任务
    delCourseTask: "/course/del",
    //4.修改一个任务信息
    updateCourseTask: "/course/update",
    //5.开始一个任务
    runCourseTask: "/course/run",
    //6.提交图片的base64编码
    uploadImage: "/course/uploadImage",
    //7.获取用户头像的base64编码
    getImage: "/course/getImage",
    //8.清空上课任务的日志
    clearCourseLog: "/course/clearLog",
    // 9.获取具体的任务日志
    getCourseLog: "/course/getCourseLog",
};

//考试模块
apiPath.examApiPath = {
    //1.获取教务任务列表
    getExamList: "/exam/getList",
    //2.添加一个任务
    addExamTask: "/exam/add",
    //3.删除一个任务
    delExamTask: "/exam/del",
    //4.修改一个任务信息
    updateExamTask: "/exam/update",
    //5.开始一个任务
    runExamTask: "/exam/run",
    //6.提交图片的base64编码
    uploadImage: "/exam/uploadImage",
    //7.获取用户头像的base64编码
    getImage: "/exam/getImage",
    //8.批量导入试题
    importQuestion: "/exam/import",
    //9.清空任务的日志
    clearExamLog: "/exam/clearLog",
    // 10.上传头像到本地
    uploadAvater:'/exam/uploadAvater',
    // 11.获取考试日志
    getExamLog:'/exam/getExamLog'
};

//题库模块
apiPath.bankApiPath = {
    //1.获取题库列表
    getBankList: "/bank/getList",
    //2.修改题目
    alertQuestion: "/bank/alert",
    //3.删除题目
    deleteQuestion: "/bank/delete",
};

//进程模块
apiPath.clusterApiPath = {
    //1.获取进程列表
    getClusterList: "/cluster/getList",
    //2.添加一个进程
    addCluster: "/cluster/add",
    //3.删除一个进程
    delCluster: "/cluster/del",
};

// 任务列队模块
apiPath.taskApiPath = {
    // 1.获取任务列表
    getTaskList: "/task/getList",
    // 2.置顶一个任务
    pinTask: "/task/pin",
    // 3.删除一个任务
    delTask: "/task/del",
};

function addBaseUrl(arr) {
    arr.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
            obj[key] = baseUrl + obj[key];
        });
    });
}

let apiArr = Object.values(apiPath);

let apiPathArr = [...apiArr];
addBaseUrl(apiPathArr);

export default apiPath;
