import Vue from 'vue'
import Vuex from 'vuex'
import rootModule from './rootModule'

Vue.use(Vuex) //Vuex.install


//获取模块定义
let floder=require.context('./modules',false,/\.js$/) //路径，是否递归文件夹，文件名后缀
floder.keys().forEach(filename=>{
    //获取模块内容
    let moduleContent=floder(filename).default
    moduleContent.namespaced=true; //增加命名空间
    //获取模块的key
    let moduleName=filename.replace(/\.js$/,'').replace(/^\.\//,'')
    rootModule.modules[moduleName]=moduleContent
})

let store=new Vuex.Store(rootModule)

export default store;