import store from '../store/index';

/**
 * 关于vueRouter钩子的生效时间
 * 1.在根vm实例初始化的时候，通过beforeCreate钩子执行路由逻辑
 * 在获取到路径对应的route对象，给$route赋值之前，执行钩子函数
 * 2.路径变化，触发historyChange事件，也会执行路由逻辑
 */
export async function tokenToSignin(to, from, next) {
    let token = localStorage.getItem('token');
    if (token && !store.state.user.isSignin) { 
        try {
            await store.dispatch('user/user_validate', token); //token登录
            await store.dispatch('user/add_route',store.state.user.userInfo); //动态添加路由
            next({ ...to, replace: true }); //通过钩子添加的路由不能立刻生效
        } catch (error) {
            store.commit('user/reset_signin')
        }
    }
    //根据登录情况进行路由跳转
    if (store.state.user.isSignin) {
        if (to.path == '/signin') {
            next('/');
        } else {
            next();
        }
    } else {
        if (to.path == '/signin') {
            next();
        } else {
            next('/signin');
        }
    }
}