import axios from 'axios'

const axiosInstance=axios.create()

//发送请求前，如果浏览器的localStorage保存了token，就放到请求头中发送给服务器
axiosInstance.interceptors.request.use(
    config=>{
        let token=localStorage.getItem('token')
        if(token){
            config.headers.Authorization='Bearer '+token
        }
        return config
    },
    err=>err
)

axiosInstance.interceptors.response.use(
    res=>{
        if(!res.data.state){
            return res.data
        }
        if(res.data.state=='success'){
            return res.data.data
        }
        else if(res.data.state=='fail'){
            // console.log('返回一个失败的promise',res.data)
            return  Promise.reject(res.data.message)
        }
        else{
            return Promise.reject('服务器没有返回值')
        }
    },
    err=>err
)

export default axiosInstance
