<template>
    <el-menu router background-color="#304156" text-color="#BFCBD9" active-text-color="#409EFF" class="SideBarWrap">
        <!-- 遍历menus -->
        <template v-for="route of userMenuPermission">

            <!--首页，特殊处理-->
            <template v-if="route.name == ''">
                <el-menu-item :key="route.path" :index="route.path">
                    <template slot="title">
                        <i :class="[route.children[0].icon, 'icon']"></i>
                        <span>{{ route.children[0].name }}</span>
                    </template>
                </el-menu-item>
            </template>

            <!-- 普通菜单 -->
            <template v-else>
                <el-submenu :key="route.path" :index="route.path">
                    <template slot="title">
                        <i :class="[route.icon,'icon']"></i>
                        <span>{{ route.name }}</span>
                    </template>
                    <el-menu-item v-for="(item, index) of route.children" :key="index" :index="route.path+'/'+item.path">
                        <template slot="title">
                            <i :class="[item.icon,'icon']"></i>
                            <span>{{ item.name }}</span>
                        </template>
                    </el-menu-item>
                </el-submenu>
            </template>

        </template>
    </el-menu>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState('user', {
            userMenuPermission: state => state.userMenuPermission
        })
    },
}
</script>

<style scoped>
.SideBarWrap {
    border-width: 0;
}
.icon{
    display: inline-block;
    width: 15px;
    font-size:13px;
    margin-right:5px
}
</style>