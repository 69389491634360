import Layout from "@/views/layout/Layout.vue";
export default [
    {
        id: 1,
        name: "",
        component: Layout,
    },
    {
        id: 11,
        name: "首页",
        component: () => import(/* webpackChunkName:"Process" */ "@v/process/Process.vue"),
    },
    {
        id: 2,
        name: "网课",
        component: Layout,
    },
    {
        id: 21,
        name: "柠檬文才网课",
        // component: () => import(/* webpackChunkName:"course_nmwc" */ "@v/course_nmwc/Nmwc.vue"),
        component: () => import(/* webpackChunkName:"course" */ "@v/course/Course.vue"),
    },
    {
        id: 22,
        name: "继续教育网课",
        // component: () => import(/* webpackChunkName:"course_ahjx" */ "@v/course_ahjx/Ahjxjy.vue"),
        component: () => import(/* webpackChunkName:"course" */ "@v/course/Course.vue"),
    },
    {
        id: 23,
        name: "君睿信息网课",
        // component: () => import(/* webpackChunkName:"course_jrxx" */ "@v/course_jrxx/Jrxx.vue"),
        component: () => import(/* webpackChunkName:"course" */ "@v/course/Course.vue"),
    },
    {
        id: 24,
        name: "超星学习通网课",
        // component: () => import(/* webpackChunkName:"course_cxxxt" */ "@v/course_cxxxt/Cxxxt.vue"),
        component: () => import(/* webpackChunkName:"course" */ "@v/course/Course.vue"),
    },
    {
        id: 25,
        name: "弘成教育网课",
        // component: () => import(/* webpackChunkName:"course_hcjy" */ "@v/course_hcjy/Hcjy.vue"),
        component: () => import(/* webpackChunkName:"course" */ "@v/course/Course.vue"),
    },
    {
        id: 26,
        name: "国开网课",
        component: () => import(/* webpackChunkName:"course" */ "@v/course/Course.vue"),
    },
    {
        id: 3,
        name: "考试",
        component: Layout,
    },
    {
        id: 31,
        name: "柠檬文才考试",
        // component: () => import(/* webpackChunkName:"exam_nmwc" */ "@v/exam_nmwc/Nmwc.vue"),
        component: () => import(/* webpackChunkName:"exam" */ "@v/exam/Exam.vue"),
    },
    {
        id: 32,
        name: "继续教育考试",
        // component: () => import(/* webpackChunkName:"exam_jxjy" */ "@v/exam_ahjx/Ahjxjy.vue"),
        component: () => import(/* webpackChunkName:"exam" */ "@v/exam/Exam.vue"),
    },
    {
        id: 33,
        name: "超星学习通考试",
        // component: () => import(/* webpackChunkName:"exam_Cxxxt" */ "@v/exam_cxxxt/Cxxxt.vue"),
        component: () => import(/* webpackChunkName:"exam" */ "@v/exam/Exam.vue"),
    },
    {
        id: 34,
        name: "弘成教育考试",
        // component: () => import(/* webpackChunkName:"exam_Cxxxt" */ "@v/exam_cxxxt/Cxxxt.vue"),
        component: () => import(/* webpackChunkName:"exam" */ "@v/exam/Exam.vue"),
    },
    {
        id: 5,
        name: "系统",
        component: Layout,
    },
    {
        id: 51,
        name: "进程管理",
        component: () => import(/* webpackChunkName:"Process" */ "@v/process/Process.vue"),
    },
    {
        id: 52,
        name: "任务列队",
        component: () => import(/* webpackChunkName:"bank_jxjy" */ "@v/task/Task.vue"),
    },
    // {
    // 	id: 4,
    // 	name: '题库',
    // 	component: Layout,
    // },
    // {
    // 	id: 41,
    // 	name: '柠檬文才题库',
    // 	component: () =>
    // 		import(
    // 			/* webpackChunkName:"bank_nmwc" */ '@v/bank_nmwc/Nmwc.vue'
    // 		),
    // },
    // {
    // 	id: 42,
    // 	name: '继续教育题库',
    // 	component: () =>
    // 		import(
    // 			/* webpackChunkName:"bank_jxjy" */ '@v/bank_ahjx/Ahjxjy.vue'
    // 		),
    // },
    // {
    // 	id: 43,
    // 	name: '超星学习通题库',
    // 	component: () =>
    // 		import(
    // 			/* webpackChunkName:"bank_cxxxt" */ '@v/bank_cxxxt/Cxxxt.vue'
    // 		),
    // },
    // {
    // 	id: 44,
    // 	name: '弘成教育题库',
    // 	component: () =>
    // 		import(
    // 			/* webpackChunkName:"bank_hcjy" */ '@v/bank_hcjy/Hcjy.vue'
    // 		),
    // },
];
