import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter) //VueRouter.install
let router=new VueRouter({
    mode:'history',
    routes:[
        {
            name:'signin',
            path:'/signin',
            //这样写说明是一个异步组件，异步组件本身是一个函数，会先返回一个注释节点，加载完毕之后会强制渲染
            component:()=>import(/* webpackChunkName:"Signin" */ '@v/signin/Signin.vue')
        }
    ]
})

import  {tokenToSignin} from './hooks'
router.beforeEach(tokenToSignin)

export default router;