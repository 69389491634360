var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layoutWrap" }, [
    _c("aside", [_c("SideBar")], 1),
    _vm._v(" "),
    _c(
      "section",
      [
        _c("NavBar"),
        _vm._v(" "),
        _c("main", [_c("keep-alive", [_c("router-view")], 1)], 1),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }