<template>
    <div class="layoutWrap">
        <aside>
            <SideBar></SideBar>
        </aside>
        <section>
            <NavBar></NavBar>
            <main>
                <keep-alive>
                    <router-view></router-view>
                </keep-alive>
            </main>
        </section>
    </div>
</template>

<script>
// import * as otherAPI from '@/api/other.js'
import SideBar from './components/SideBar.vue';
import NavBar from './components/NavBar.vue'
export default {
    components: {
        SideBar,
        NavBar
    },
    // async mounted(){
    //     await otherAPI.viewCount()
    // }
}
</script>

<style lang="scss" scoped>
.layoutWrap {
    overflow: hidden;

    aside {
        float: left;
        height: 100vh;
        width: 210px;
        background-color: #304156;
        overflow: hidden;
    }

    section {
        float: right;
        height: 100vh;
        width: calc(100% - 210px);
        background-color: #f7f9fb;
        overflow: auto;
    }

    main {
        padding: 20px;
        background-color: #f7f9fb;
    }
}
</style>